<script>
import { computed } from '@vue/reactivity'
import { useStore } from 'vuex'
export default {
  name: 'ColorOption',
  props: {
    option: {
      require: true,
      type: Object
    }
  },
  setup () {
    const store = useStore()
    const selectColor = (option) => store.dispatch('system/selectColor', option)
    const imageDimensions = computed(() => {
      let str = ''
      const width = 150
      const height = 100
      if (width) {
        str = Number.parseInt(width)
      }
      str += 'x'
      if (height) {
        str += Number.parseInt(height)
      }
      return str
    })
    return {
      imageDimensions,
      selectColor
    }
  }
}
</script>
<template>
  <div class="card pointer color">
    <TooltipColor v-if="option.image" :tooltip="option.tooltip">
      <img :src="$filters.image(option.image, imageDimensions)" class="card-img-top">
    </TooltipColor>
    <div v-else :class="{'card-image-top': option.image, 'card-background': !option.image}" :style="{background: option.value + ' !important' }" id="image"></div>
    <div class="card-body">
      <label class="option-color__header" :for="'color-' + option.id">
        <span class="option-color__label">{{ option.name }}</span>
      </label>
    </div>
    <div class="card-footer">
      <div class="option-color__footer">
        <div class="form-check">
          <input
            class="form-check-input"
            type="radio"
            :id="'color-' + option.id"
            :checked="option.selected"
            @click="selectColor(option)">
          <label class="form-check-label" :for="'color-' + option.id">
            {{ option.label }}
          </label>
        </div>
      </div>
    </div>
  </div>
</template>
<style scoped>
.card-body {
  padding: 0;
}
.color .card-img-top {
  height: 105px;
}
.color .card-background {
  height: 105px;
}
</style>
