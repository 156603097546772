<template>
  <div class="row">
    <div class="col-md-12">
      <h3>{{ step.title }}</h3>
      <p v-if="step.description" class="step-description">{{ step.description }}</p>
    </div>
    <div class="row mb-3">
      <div class="col-md-12">
        <p>{{ step.label }}</p>
      </div>
      <div v-for="color in regularColors" :key="color.id" class="col-2">
        <ColorOption :option="color"/>
      </div>
    </div>
    <div v-if="specialColors.length > 0" class="row">
      <div class="col-md-12">
        <div class="option__title mb-3">Spezialfarben{{ step.label_special }} (+{{ step.price_special }}%)</div>
      </div>
      <div class="row">
        <div v-for="color in specialColors" :key="color.id" class="col-2">
          <ColorOption :option="color"/>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { computed } from '@vue/reactivity'
import { useStore } from 'vuex'
import ColorOption from '@/pages/system/steps/option/colorOption'
export default {
  components: {
    ColorOption
  },
  setup () {
    const store = useStore()
    const regularColors = computed(() => {
      const step = store.getters['system/currentStep']
      return step.colors.filter(c => !c.special && c.visible)
    })
    const specialColors = computed(() => {
      const step = store.getters['system/currentStep']
      return step.colors.filter(c => c.special && c.visible)
    })
    const step = computed(() => {
      return store.getters['system/currentStep']
    })
    return {
      step,
      regularColors,
      specialColors
    }
  }
}
</script>
